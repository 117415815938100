import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210"
      height="146"
      fill="none"
      viewBox="0 0 210 146"
    >
      <rect
        width="7.21"
        height="77.229"
        x="0.357"
        y="68.629"
        fill="#D82CD7"
        rx="2.914"
      ></rect>
      <rect
        width="7.21"
        height="79.414"
        x="89.765"
        y="66.443"
        fill="#D82CD7"
        rx="2.914"
      ></rect>
      <rect
        width="7.21"
        height="32.786"
        x="45.061"
        y="113.071"
        fill="#D82CD7"
        rx="2.914"
      ></rect>
      <rect
        width="7.21"
        height="75.043"
        x="134.47"
        y="70.814"
        fill="#D82CD7"
        rx="2.914"
      ></rect>
      <rect
        width="7.21"
        height="107.829"
        x="179.174"
        y="38.029"
        fill="#D82CD7"
        rx="2.914"
      ></rect>
      <rect
        width="7.21"
        height="119.486"
        x="22.709"
        y="26.371"
        fill="#D82CD7"
        rx="2.914"
      ></rect>
      <rect
        width="7.21"
        height="38.614"
        x="112.118"
        y="107.243"
        fill="#D82CD7"
        rx="2.914"
      ></rect>
      <rect
        width="7.21"
        height="130.414"
        x="67.413"
        y="15.443"
        fill="#D82CD7"
        rx="2.914"
      ></rect>
      <rect
        width="7.21"
        height="145.714"
        x="156.822"
        y="0.143"
        fill="#D82CD7"
        rx="2.914"
      ></rect>
      <rect
        width="7.21"
        height="53.914"
        x="202.247"
        y="91.943"
        fill="#D82CD7"
        rx="2.914"
      ></rect>
    </svg>
  );
}

export default Icon;
