import React from "react";
import Header from "@layouts/Header";
import Info from "@sections/Info";
import InfoVertical from "@sections/InfoVertical";
import ChartBox from "@components/ChartBox/ChartBox";
import ContactForm from "@containers/ContactForm";
import Experts from "@sections/Experts";

const Blog = ({ data = {} }) => {
  const {
    header = {},
    firstSection = {},
    charts = {},
    plan = {},
    lastSection = {},
    contact = {},
    experts = {},
  } = data;

  return (
    <div className="blog">
      <Header {...header} />
      <Info {...firstSection} />
      <InfoVertical {...charts}>
        {charts.boxes.map(box => {
          return <ChartBox {...box} />;
        })}
      </InfoVertical>
      <Info {...plan} />
      <Info {...lastSection} />
      <ContactForm {...contact} />
      <Experts {...experts} />
    </div>
  );
};

export default Blog;
