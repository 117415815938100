import React from "react";

import ChartIcon from "@components/svg/ChartIcon";

import "./ChartBox.styles.scss";

const ChartBox = ({ title, description }) => {
  return (
    <div className="chart-box">
      <div className="chart-box__details">
        <p className="chart-box__title">{title}</p>
        <p className="chart-box__description">{description}</p>
      </div>
      <div className="chart-box__image">
        <ChartIcon />
      </div>
    </div>
  );
};

export default ChartBox;
